import {
  CarouselAdditionInitializationBlock,
  forEachRegisteredCarouselController,
} from "../carousel-handles";
import { setIntervalDuration } from "../interval/interval-duration-set";

/**
 * The interval duration for carousel elements with data-start-on-scroll="true".
 */
const START_ON_SCROLL_INTERVAL_DURATION_MILLISECONDS = 10_000;

const initializeStartOnScrollHandler: CarouselAdditionInitializationBlock = (
  carouselRootElement
) => {
  if (!carouselRootElement.matches(':scope[data-start-on-scroll="true"]')) {
    return;
  }

  new IntersectionObserver(
    handleCarouselRootElementIntersections,
    intersectionObserverOptions
  ).observe(carouselRootElement);
};

const handleCarouselRootElementIntersections: IntersectionObserverCallback = (
  intersections,
  observer
) => {
  for (const intersection of intersections) {
    if (intersection.isIntersecting) {
      if (!(intersection.target instanceof HTMLElement)) {
        continue;
      }
      setIntervalDuration(
        intersection.target,
        START_ON_SCROLL_INTERVAL_DURATION_MILLISECONDS
      );
      observer.unobserve(intersection.target);
      observer.disconnect();
    }
  }
};

const intersectionObserverOptions = {
  threshold: 0.8,
} as const satisfies IntersectionObserverInit;

forEachRegisteredCarouselController(initializeStartOnScrollHandler);
