/**
 * Set the interval duration property of a {@link CarouselController carousel}
 * slider root element.
 *
 * This function ensures that the interval handlers, implemented in
 * {@link initializeIntervalDurationHandler}, have been or will be registered.
 */
export const setIntervalDuration = (
  carouselRootElement: HTMLElement,
  intervalMilliseconds: number
) => {
  if (Number.isNaN(intervalMilliseconds)) {
    delete carouselRootElement.dataset["interval"]; // eslint-disable-line no-param-reassign
  } else {
    // We import the init script in order to ensure that it ran its
    // side effects once. For this specific script, this means that
    // the interval handlers are either already registered or will
    // be registered in the future through the imported init script.
    import("./interval-init");
    carouselRootElement.dataset["interval"] = `${intervalMilliseconds}`; // eslint-disable-line no-param-reassign
  }
};
